import React, { useEffect, useState } from "react";
import {
  HomeStyle,
  NavStyle,
  SectionFiveStyle,
  SectionFourStyle,
  SectionOneStyle,
  SectionThreeStyle,
  SectionTwoStyle,
} from "./style";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../../assests/logo.png";
import imageOne from "../../assests/imageOne.jpg";
import verified from "../../assests/verified.svg";
import imageTwo from "../../assests/imageTwo.jpg";
import imageThree from "../../assests/imageThree.jpg";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isBackdropVisible, setIsBackdropVisible] = useState(false);
  const navigate = useNavigate();

  const gotoContact = () => {
    navigate("/contact-us");
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("getstarted");
      if (!section) return;

      const rect = section.getBoundingClientRect();
      const sectionCenter = rect.top + rect.height / 2;
      const viewportCenter = window.innerHeight / 2;

      setIsBackdropVisible(
        Math.abs(sectionCenter - viewportCenter) < rect.height / 2
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <HomeStyle>
      <NavStyle>
        <div className="container">
          <div className="navigation">
            <div className="left" data-aos="fade-right">
              <a>
                <img className="logo" src={logo} />
              </a>
            </div>
            <div className="right" data-aos="fade-left" onClick={gotoContact}>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </NavStyle>

      <SectionOneStyle>
        <div className="background-clr">
          <div className="container">
            <div className="section-one">
              <div className="left-content" data-aos="fade-up">
                <span className="sec-one-logo">
                  <img src={logo} alt="logo-mileXchange" />
                </span>
                <p className="top-description">
                  A driver app that allows you to keep 100% of fees** & tips.
                </p>
                <span className="first-form" onClick={gotoContact}>
                  <button>I want in! Text me when my area is available</button>
                </span>
                <p className="bottom-text">**Minimum fees of $8 per drive.</p>
              </div>

              <div className="image-side">
                <img src={imageOne} alt="Left side" />
              </div>
            </div>
          </div>
        </div>
      </SectionOneStyle>

      <SectionTwoStyle>
        <div className="container">
          <div className="section-two">
            <h1 className="section-two-heading">
              Are you frustrated with gig apps that:
            </h1>
            <div className="section-two-content">
              {[
                "hold your money hostage?",
                "do not value you?",
                "require you to drive to reach payout levels?",
                "do not understand your independence?",
                "change your pay regularly?",
                "are not transparent with revenue?",
              ].map((text, index) => (
                <div className="content-text" key={index}>
                  <span className="verified-icon">
                    <img src={verified} alt="Verified icon" />
                  </span>
                  <span>{text}</span>
                </div>
              ))}
            </div>
            <div className="section-two-btn" onClick={gotoContact}>
              <button>I want in!</button>
            </div>
          </div>
        </div>
      </SectionTwoStyle>

      <SectionThreeStyle>
        <div className="background-clr">
          <div className="container">
            <div className="section-three">
              <div className="section-three-img" data-aos="fade-up">
                <img src={imageTwo} />
              </div>

              <div className="section-three-content">
                <p className="three-main-heading" data-aos="fade-down">
                  MAKE YOUR GIG THE BUSINESS YOU IMAGINED
                </p>
                <span
                  className="three-form"
                  data-aos="fade-up"
                  onClick={gotoContact}
                >
                  <button>I definitely want in!</button>
                </span>
                <p className="three-bottom-text" data-aos="fade-up">
                  Take back control with{" "}
                  <span className="sec-three-logo">
                    <img src={logo} alt="logo-mileXchange" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </SectionThreeStyle>

      <SectionFourStyle>
        <div className="container">
          <div className="section-four-card">
            <h1 className="sec-heading" data-aos="fade-up">
              What's In It For Me
            </h1>
            <div className="sec-cards">
              <div className="card" data-aos="fade-up">
                <div>
                  <p className="card-heading">KEEP</p>
                  <p className="card-heading">100% of </p>
                  <p className="card-heading">Delivery Fees</p>
                </div>

                <p className="card-description">after all you have expenses</p>
              </div>
              <div className="card" data-aos="fade-up">
                <div>
                  <p className="card-heading">KEEP </p>
                  <p className="card-heading">100% of </p>
                  <p className="card-heading">Tips</p>
                </div>

                <p className="card-description">you work hard for your money</p>
              </div>
              <div className="card" data-aos="fade-up">
                <div>
                  <p className="card-heading">Really</p>
                  <p className="card-heading">OWN Your</p>
                  <p className="card-heading">Business</p>
                </div>

                <p className="card-description">freedom</p>
              </div>
            </div>
            <div className="sec-card-bottom" data-aos="fade-up">
              <span data-aos="fade-up">
                After a free trial, there is a subscription fee of just less
                than $1/day
              </span>
              <span className="card-form-btn" onClick={gotoContact}>
                <button>Put me on this List</button>
              </span>
            </div>
          </div>
        </div>
      </SectionFourStyle>

      <SectionFiveStyle id="getstarted">
        <div
          className={`purple_backdrop ${isBackdropVisible ? "visible" : ""}`}
        ></div>
        <div className="container">
          <div className="sec-five">
            <div className="five-left-content" data-aos="fade-up">
              <div data-aos="fade-up">
                <h1 className="five-main-heading">This is about</h1>
                <h1 className="five-main-heading C">YOU the DRIVER</h1>
              </div>
              <div>
                <p className="top-description1">
                  We heard your cry for greater earnings
                </p>
                <p className="top-description2">
                  <span className="sec-five-logo">
                    <img src={logo} alt="logo-mileXchange" />
                  </span>{" "}
                  is your solution
                </p>
              </div>
              <span className="last-form-btn" onClick={gotoContact}>
                <button>I am very interested!!!</button>
              </span>
            </div>
            <div className="image-side" data-aos="fade-up">
              <img src={imageThree} alt="Left side" />
            </div>
          </div>
        </div>
      </SectionFiveStyle>
    </HomeStyle>
  );
};

export default Home;
